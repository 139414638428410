<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>{{ param.headerTitle }}</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">            
            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="100px">
                    <col>
                    <col width="100px">
                    <col>
                </colgroup>
                <tbody>
                    <tr>
                        <th><label>등록자</label></th>
                        <td>
                            <v-text-field class="jh-form is-md is-trns" v-model="FST_USER_NM" disabled></v-text-field>
                        </td>
                        <th><label>등록일시</label></th>
                        <td>
                            <v-text-field class="jh-form is-md is-trns" v-model="FST_BRD_DT" disabled></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <th><label>수정자</label></th>
                        <td>
                            <v-text-field class="jh-form is-md is-trns" v-model="LAST_USER_NM" disabled></v-text-field>
                        </td>
                        <th><label>수정일시</label></th>
                        <td>
                            <v-text-field class="jh-form is-md is-trns" v-model="LAST_BRD_DT" disabled></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <th><label class="is-required">제목</label></th>
                        <td colspan="3">
                            <v-text-field class="jh-form is-md is-trns" v-model="BRD_TIT"></v-text-field>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="jh-ui-header is-mt-10">
                <h3>파일목록</h3>
                <div class="is-right">
                </div>
            </div>
            <v-data-table
                class="jh-grid"
                fixed-header
                hide-default-footer
                height="62px"
                :headers="gridDataHeaders"
                :items="gridDataText"
                v-model="gridListSelected"
                item-key="index"
            >
              <template v-slot:item.DOWNLOAD = {item}>
                  <v-btn class="jh-btn is-sm is-fill" @click="fileDownload(item)">다운로드</v-btn>
              </template>
            </v-data-table>
        </div>
        <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')">
            <v-btn class="jh-btn is-md is-del" @click="clickBtnDel">삭제</v-btn>
            <v-btn class="jh-btn is-md is-main" @click="clickBtnModify" v-if="this.mixin_set_btn(this.$options.name, 'btnMody')">수정</v-btn>
        </div>

        <v-dialog v-if="dialogE020602P01" max-width="500" persistent v-model="dialogE020602P01">
            <dialog-E020602P01
                :param="E020602P01Param"
                @hide="hideDialog('E020602P01')"
                @Qsave="hideDialog('E020602P01')"
            >
            </dialog-E020602P01>
        </v-dialog>
    </div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {mixin} from "@/mixin/mixin.js";
import DialogE020602P01 from "@/views/page/E020602P01";

export default {
  name      : "MENU_E020602P02", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin],
  props     : {
    param: {
      type: Object,
    },
    p02  : {
      type   : Boolean,
      default: false,
    },
  },
  components: {
    DialogE020602P01,
  },
  data() {
    return {
      popupTitle: '',

      dialogE020602P01: false,
      E020602P01Param : {},

      //dropIteam
      allCustItem: [],

      //첨부파일 그리드 설정 변수
      gridDataHeaders : [
        // {text: "No", value: "index", width: "100px", align: "center", sortable: false,},
        {text: "파일명", value: "ORIGINAL_FILENAME", sortable: false,},
        {text: "크기", value: "FILE_SIZE", width: "100px", align: "center", sortable: false,},
        {text: "다운로드", value: "DOWNLOAD", width: "100px", align: "center", sortable: false,},
        {text: "다운로드수", value: "DNLOD_CNT", width: "80px", align: "center", sortable: false,},
      ],
      gridDataText    : [],
      gridListSelected: [],

      page        : 1,
      pageCount   : 0,
      itemsPerPage: 100,
      footerSubmit: '',

      //v-model
      ASP_NEWCUST_KEY: '',
      FST_USER_NM    : '',
      FST_BRD_DT     : '',
      LAST_USER_NM   : '',
      LAST_BRD_DT    : '',
      BRD_TIT        : '',

      artMsg: {
        delChk: '삭제하시겠습니까?'
      },

    }
  },
  methods: {
    async init() {
      this.allCustItem = await this.mixin_getAllAspData();
    },

    //***************************************
    //         dbData 날짜 형식 변경
    //***************************************
    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join('/');
      let hour = [data.substr(8, 2), data.substr(10, 2), data.substr(12, 2)].join(':');

      return year + ' ' + hour;
    },

    //***************************************
    //            상세조회
    //***************************************
    async detailPopup() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"]     = "/api/board/detail-popup/inqire  ";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["SERVICE"] = 'board.detail-popup';
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      // sendData 세팅
      //BRD_ID||BRD_NO||ASP_NEWCUST_KEY
      requestData.sendData["ASP_NEWCUST_KEY"] = this.params.aspNewCustKey;
      requestData.sendData["BRD_NO"]          = this.params.brdNo;
      requestData.sendData["BRD_ID"]          = this.params.brdId;

      let response = await this.common_postCall(requestData);
      this.detailPopupCallback(response);
    },

    async detailPopupCallback(response) {
      console.log(response);
      let data = response.DATA[0]

      this.ASP_NEWCUST_KEY = data.ASP_NEWCUST_KEY;
      this.FST_USER_NM     = data.FST_USER_NM;
      this.FST_BRD_DT      = this.parseDateTime(data.FST_BRD_DT);
      this.LAST_USER_NM    = data.LAST_USER_NM;
      this.LAST_BRD_DT     = this.parseDateTime(data.LAST_BRD_DT);
      this.BRD_TIT         = data.BRD_TIT;

      // 첨부파일 dataList 호출
      let resList = await this.mixin_getFileList(data.FILE_GROUP_KEY);
      this.setFileList(resList)
    },

    //*********************************
    //        첨부파일 리스트 set
    //*********************************,
    setFileList(listData) {
      this.gridDataText = [];

      for (let i in listData) {
        let tempItem          = listData[i];
        tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
        tempItem["index"]     = ++i;

        this.gridDataText.push(tempItem);
      }
    },

    //*********************************
    //        첨부파일 다운로드
    //*********************************
    async fileDownload(item) {
      let busiType = "chat";   //palette, phone, chat, km, bbs
      let pathType = "images"; //publics,files,images,videos,inqrytypeimage

      await this.mixin_fileDownload(item, busiType, pathType)
    },

    //***************************************
    //            수정 Button 팝업
    //***************************************
    clickBtnModify() {
      this.E020602P01Param = {
        flag  : 'modify',
        headerTitle: "이미지 수정",
        userId: this.$store.getters['userStore/GE_USER_ID'],
        bizCd : this.param.bizCd,
        bizNm : this.param.bizNm,
        item  : this.param.item,
      };

      this.showDialog('E020602P01');
    },

    //***************************************
    //             팝업 창 닫기
    //***************************************
    clickPopupClose() {
      this.$emit('hide')
    },

    //***************************************
    //         삭제버튼 모달 이벤트
    //***************************************
    clickBtnDel() {
      this.common_confirm(this.artMsg.delChk, this.clickDelY, null,
          this.clickDelN, null, "error");
    },

    async clickDelY() {
      let requestData = {
        headers : {},
        sendData: {}
      }

      requestData.headers["URL"]     = "/api/board/detail-popup/delete";
      requestData.headers["SERVICE"] = "board.detail-popup";
      requestData.headers["METHOD"]  = "delete";
      requestData.headers["ASYNC"]   = true;
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"]          = this.params.brdId;
      requestData.sendData["BRD_NO"]          = this.params.brdNo
      requestData.sendData["PROC_ID"]         = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData["ASP_NEWCUST_KEY"] = this.params.aspNewCustKey;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG == false) {
        this.common_alert("게시물이 삭제되었습니다.", "done");

        this.$emit('submit');
      }
      this.clickPopupClose();
    },

    clickDelN() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    clickBtnPopupClose() {
      this.$emit('hide')
    },

    showDialog(menu) {
      this[`dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`dialog${menu}`] = false;
      this.detailPopup();
    },

  },

  mounted() {
    this.init();
    this.detailPopup();
  },

  computed: {
    params() {
      return {
        aspNewCustKey: this.param.bizCd,
        brdNo        : this.param.item.BRD_NO,
        brdId        : this.param.item.BRD_ID,
      }
    },
  },
};
</script>

<style></style>
