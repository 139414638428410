<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>상담이미지 관리</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
          <colgroup>
            <!-- <col width="55px">
            <col width="250px">
            <col width="60px">
            <col width="250px"> -->
            <col width="40px">
            <col width="150px">
            <col width="90px">
            <col width="100px">
            <col width="200px">
            <col>
          </colgroup>
          <tr>
            <!-- <th><label>조회기간</label></th>
            <td>
              <div class="jh-cols">                        
                <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                </v-menu>
                <span class="jh-unit">~</span>                       
                <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                </v-menu>
              </div>
            </td> -->
            <th><label>제목</label></th>
            <td>
              <v-text-field class="jh-form" clearable name="name" @keypress.enter="getImageList" v-model="BRD_TIT"></v-text-field>
            </td>
            <th><label>최종 등록자</label></th>
            <td>
              <v-text-field class="jh-form" clearable name="name" @keypress.enter="getImageList" v-model="USER_NM"></v-text-field>
            </td>
            <td class="has-search">
              <v-btn class="jh-btn is-search" @click="getImageList">조회</v-btn>
            </td>
          </tr>
        </table>
      </div>

      <div class="jh-form-wrap">
        <div class="jh-ui-header">
          <h2>상담이미지목록</h2>
          <div class="is-right">
            <label class="jh-label">보기정렬</label>
            <v-radio-group class="jh-radio-group" v-model="CONST_BTNCOUNT_GROUP">
              <v-radio v-for="i in CONST_BTNCOUNT.TO" :key="i"  v-if="i >= CONST_BTNCOUNT.FROM" :label=" `${i}열`" :value="i" @click="changeRow(i)"></v-radio>
            </v-radio-group>
            <div class="jh-split"></div>
            <v-btn class="jh-btn is-round is-main" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" @click="newTable">이미지 등록</v-btn>
          </div>
        </div>
        
        <div class="jh-list-image" style="height: 602px;">
          <p class="jh-no-data" :class="historyList.length > 0 ? 'is-hidden': ''"><span>현재 데이터가 없습니다.</span></p>
          <ul :class="SelectAlign" v-if="historyList.length > 0">
            <li v-for="i in historyList" :key="i" @click="vcardClick(i)">
                <div class="is-image-title" v-html="i.BRD_TIT"></div>
                <div class="is-image-date" v-html="i.FST_BRD_DT_D"></div>
                <img :src="i.src">
            </li>
          </ul> 
         
          <v-dialog v-if="dialogE020602P01" max-width="500" persistent v-model="dialogE020602P01">
            <dialog-E020602P01
              headerTitle="이미지 등록"
              :param="E020602P01Param"
              p01
              @hide="hideDialog('E020602P01')"
              @submit="submitDialog('E020602P01')"
            >
            </dialog-E020602P01>
          </v-dialog>
          <v-dialog v-if="dialogE020602P02" max-width="750" persistent v-model="dialogE020602P02">
            <dialog-E020602P02
                headerTitle="상세내용보기"
                :param="E020602P02Param"
                p02
                @hide="hideDialog('E020602P02')"
                @submit="submitDialog('E020602P02')"
            >
            </dialog-E020602P02>
          </v-dialog>
        </div>
        <div class="jh-pager">
          <v-pagination v-model="page" :length="pages" :total-visible="10" @input="updatePage"></v-pagination>
          <span class="jh-counter is-left">총 <em>{{ historyList.length }}</em> 건</span>
        </div>
      </div>

    <!-- <div class="box-wrap">
      <template>
        <table style="display: grid;">
          <div class="image_list">
            <v-card
                class="mx-auto"
            >
              <v-container fluid v-if="historyList.length > 0">
                <v-row
                    v-for=" i in rows"
                    :key="i">
                  <v-col
                      v-for="j in cols"
                      :key="j">
                    <v-card v-if="i == 1 && historyList[j-1] != undefined" @click="vcardClick(historyList[j-1])">
                      <v-card-title v-text="historyList[j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background-image': getFileSrc(1,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 2 && historyList[cols + j-1] != undefined"
                            @click="vcardClick(historyList[cols + j-1])">
                      <v-card-title v-text="historyList[cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background-image': getFileSrc(2,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 3 && historyList[cols + cols + j-1] != undefined "
                            @click="vcardClick(cols + cols + j-1)">
                      <v-card-title v-text="historyList[cols + cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background-image': getFileSrc(3,cols,j), 'background-size': 'cover'}"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </table>
      </template>
      <v-dialog v-if="dialogE020602P01" max-width="500" persistent v-model="dialogE020602P01">
        <dialog-E020602P01
          headerTitle="이미지 등록"
          :param="E020602P01Param"
          p01
          @hide="hideDialog('E020602P01')"
          @submit="submitDialog('E020602P01')"
        >
        </dialog-E020602P01>
      </v-dialog>
      <v-dialog v-if="dialogE020602P02" max-width="1200" persistent v-model="dialogE020602P02">
        <dialog-E020602P02
            headerTitle="상세내용보기"
            :param="E020602P02Param"
            p02
            @hide="hideDialog('E020602P02')"
            @submit="submitDialog('E020602P02')"
        >
        </dialog-E020602P02>
      </v-dialog>
    </div> -->
  </div>
</template>

<script>
import DialogE020602P01 from "./E020602P01.vue";
import DialogE020602P02 from "./E020602P02.vue";
import api from "@/store/apiUtil.js";
import {mixin} from "@/mixin/mixin.js";
import {mapState, mapGetters, mapActions} from "vuex";

export default {
  name      : "MENU_E020602", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin], // mixin 등록
  components: {
    DialogE020602P01,
    DialogE020602P02
  },

  data() {
    return {
      ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,

      SelectAlign: 'is-col-5', //CONST_BTNCOUNT_GROUP의 숫자를 is-col- 다음에 표기
      CONST_BTNCOUNT_GROUP: 5,
      CONST_BTNCOUNT  : {"FROM": 3, "TO": 7},
      dialogE020602P01: false,
      dialogE020602P02: false,
      E020602P01Param : {},
      E020602P02Param : {},

      URLData         : '',
      ASP_CUST_KEY    : '',
      ASP_NEWCUST_KEY : '',
      selBexBizV      : [],
      gridListDataText: [],
      USER_NM : '',

      // as is list data
      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',

      BRD_TIT  : '', // 검색할 제목
      BRD_ID   : '',
      PAGES_CNT: 1, // header 현재 보여지는 페이지 기본 1
      ROW_CNT  : 1000, // 한 페이지에 보여줄 게시글의 수
      pageCount: 1,

      TOT_COUNT: '', // 총 게시글 수

      //페이징
      page       : 1,
      pageSize   : 10,
      listCount  : 0,
      historyList: [],
      rows       : 2,

      // css 열 바꿈
      articles: [],
      cols    : 5,


      alertMsg: {
        comChk     : "회사구분란에서 회사를 선택 후 이용해주십시오.",
        newWriteChk: '조회 후 작성 가능합니다.'

      },

      alertIcon: {
        noti: "jh-alert-icon is-success",
        err : "jh-alert-icon is-error",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },
      dropItems: [],

      menuStartDate: false,
      menuEndDate: false,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      IMAGE_TYPE   : '',
      FILE_EXTS    : '',
      FILE_BLOB    : '',
      FILE_PATH    : '',
      SAVE_FILENAME: '',
      viewBtnGrp   : 2
    };
  },
  methods: {

    // async appendLog(data){
    //   this.$store.commit("logStore/MU_LOG", data);
    // },

    async getSelBoxList() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/code/common/code-book/sender/aspinqry";
      requestData.headers["SERVICE"] = "code.common.code-book.sender";
      requestData.headers["METHOD"] = "aspinqry";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_USER_ID"] = this.ASP_USER_ID;

      let response = await this.common_postCall(requestData);
      this.getSelBoxListThen(response);
    },

    //회사구분 셀렉트박스 아이템 호출 후처리
    getSelBoxListThen(response) {
      this.dropItems = response[this.ASP_USER_ID];
      this.selBexBizV.CD = this.dropItems[0].ASP_CUST_KEY;
      this.selBexBizV.CD_NM = this.dropItems[0].CD_NM;
      var user = {ASP_CUST_KEY: this.selBexBizV.CD};
      console.log("user   " , user); // 회사 코드 확인완료
      console.log("selBexBizV.CD   " , this.selBexBizV.CD); // 회사 코드 확인완료
      console.log("selBexBizV.CD_NM    " , this.selBexBizV.CD_NM ); // 회사 코드 확인완료
      this.getImageList()
    },

    getListCM() { // 회사구분 콤보박스 조회
      var date = new Date("00:00:01");
      console.log("#### date=", date);
      console.log("#### seconds=", date.getSeconds());

      var user = {ASP_CUST_KEY: this.selBexBizV.CD};
      console.log(user); // 회사 코드 확인완료

    },

    getBrdId() {
      let menuList = {};
      menuList = this.$store.getters["userStore/GE_USER_MENU"];
      // for (let i in menuList) {
      //   if (menuList[i].C_NODE_NO === "M810607") {
      //     let tempUrl = menuList[i].NODE_URL;
      //     let idIndex = tempUrl.indexOf("BRD_ID=");
      //     console.log("bordID ::::::::::::::" + tempUrl.substring(idIndex + 7));
      //     return tempUrl.substring(idIndex + 7);
      //   }
      // }
      // debugger;
      //
      // for (let i in menuList) {
      //   let tempUrl = {};
      //   if (menuList[i].C_NODE_NO === "M81") {
      //     for (let j in menuList[i].child) {
      //       if (menuList[i].child[j].C_NODE_NO === "M8106") {
      //         tempUrl = menuList[i].child[j];
      //
      //         for (let i in tempUrl.child) {
      //           if (tempUrl.child[i].C_NODE_NO === "M810607") {
      //             let brdIdUrl = tempUrl.child[i].NODE_URL_MENUID;
      //             let idIndex = brdIdUrl.indexOf("BRD_ID=");
      //             return brdIdUrl.substring(idIndex + 7);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      console.log("menuListmenuListmenuList" +  menuList);


      for(let i of menuList){
        if(i.C_NODE_NO == "E02"){
          for(let j of i.child){
            if(j.C_NODE_NO == "E0206"){
              for(let k of j.child){
                if(k.C_NODE_NO == "E020602"){
                  let brdIdUrl = k.NODE_URL_MENUID;
                  let idIndex = brdIdUrl.indexOf("BRD_ID=");
                        console.log("menuListmenuListmenuList     " +  brdIdUrl.substring(idIndex + 7));

                  return brdIdUrl.substring(idIndex + 7);
                }
              }
            }
          }
        }
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },

    formatDate2(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}${month}${day}`
    },

    showDialog(type) {
      this[`dialog${type}`] = true;
    },

    hideDialog(type) {
      this[`dialog${type}`] = false;
      this.getImageList();
    },

    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
      this.getImageList();
    },


    async getImageList() { // 조회 버튼 클릭

      this.historyList = [];

      // 해당 페이지 접속 시 회사선택은 필수사항 알람창 띄우기
      if (this.selBexBizV.CD == null || this.selBexBizV.CD == "") {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg              : this.alertMsg.comChk,
          iconClass        : this.alertIcon.err,
          type             : this.alertType.default,
        });
        return false;
      }

      let imageListUrl = "/api/chat/setting/image-manage/list";
      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = imageListUrl;
      requestData.headers["SERVICE"] = "chat.setting.image-manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["PAGES_CNT"] = this.PAGES_CNT;
      requestData.headers["ROW_CNT"] = this.ROW_CNT;
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData = {};
      requestData.sendData["ASP_NEWCUST_KEY"] = this.selBexBizV.CD;
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      // requestData.sendData["START_DT"] = this.formatDate2(this.dates[0]);
      // requestData.sendData["END_DT"] = this.formatDate2(this.dates[1]);
      requestData.sendData["BRD_TIT"] = this.BRD_TIT;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["PAGES_CNT"] = this.PAGES_CNT;
      requestData.sendData["ROW_CNT"] = this.ROW_CNT;
      requestData.sendData["ASP_USER_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["ASP_CUST_KEY"] = this.selBexBizV.CD;

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        if(! Object.keys(response).length === 0){
              console.log("w222222222222222222222         " + this.BRD_ID);

          this.setImageList(response);

        }else{
                console.log("1111111111111111111         " + this.BRD_ID);
          this.gridListDataText["BRD_ID"] = this.BRD_ID;
          // this.gridListDataText[0].BRD_ID = this.BRD_ID;

                console.log("this.gridListDataText.BRD_ID         " + this.gridListDataText.BRD_ID);
          this.setImageList(response);

          
        }

      }


      // let date = new Date();

      // let Y = date.getFullYear();
      // let M = date.getMonth();
      // let D = date.getDate();
      // let H = date.getHours();
      // let Min = date.getMinutes();
      // let S = date.getSeconds();

      // H = H < 10 ? ('0' + H) : H;
      // Min = Min < 10 ? ('0' + Min) : Min;
      // S = S < 10 ? ('0' + S) : S;

      // let data = "시간 : " + Y + ' ' + M + ' ' + D + '  ' + H + ':' + Min + ":" + S + "    |     api : getImageList \n"; 

      // this.appendLog(data);
    },

    async setImageList(response) {
      let header = response.HEADER;
      // let data = response.DATA;
      let data = await this.mixin_imageView('chat', 'images', response.DATA);
      console.log("data");

      console.log(data);
      console.log("data");
      // this.gridListDataText = response.DATA;
      this.articles = Object.assign([], data);

      //페이징
      this.initPage();

    },

    // 3, 4, 5, 6, 7열 클릭 시 보여주는 게시글 조절
    changeRow(cols) {
      //histroyList =  한 페이지에 들어가는 articles
      this.cols = cols; //3
      this.pageSize = cols < 6 ? cols * 2 : cols * 3

      this.historyList = this.articles.slice(0, this.pageSize);
      //row 구하기
      let totalLength = this.historyList.length; //6
      if (totalLength % cols > 0) {
        this.rows = Math.ceil(totalLength / cols);
      } else {
        this.rows = totalLength / cols;
      }

      //페이징
      this.initPage();

      //보기정렬 세팅
      this.SelectAlign = 'is-col-'+cols;

    },

    initPage() {
      let _this = this;
      _this.page = 1;
      _this.listCount = _this.articles.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.articles;
      } else {
        _this.historyList = _this.articles.slice(0, _this.pageSize);
      }


            console.log("_this.historyList_this.historyList  " + JSON.stringify(_this.historyList));

    },

    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.articles.slice(_start, _end);
      _this.page = pageIndex;
    },

    getFileSrc(row,cols,j){

      let ojbIdx  = (row-1) * cols;
      let strImageUrl = "";

      if (row == 1){
        strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
      } else if (row == 2) {
        strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
       } else if (row == 3) {
        strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
      }
            console.log("strImageUrl   ", strImageUrl);

       return strImageUrl;
    },
    newTable() {
      console.log("this.BRD_ID" , this.BRD_ID  )
      if (this.isEmpty(this.BRD_ID)) {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }

      this.E020602P01Param = {
        flag       : 'new',
        headerTitle: "이미지 등록",
        userId     : this.$store.getters['userStore/GE_USER_ID'],
        bizCd      : this.selBexBizV.CD,
        bizNm      : this.selBexBizV.CD_NM,
        item       : this.gridListDataText,
      }

      this.showDialog('E020602P01');
    },

    vcardClick(item) {
      console.log(item)

      this.E020602P02Param = {
        headerTitle: "상세내용보기",
        userId     : this.$store.getters['userStore/GE_USER_ID'],
        bizCd      : this.selBexBizV.CD,
        bizNm      : this.selBexBizV.CD_NM,
        item       : item,
      }

      this.showDialog('E020602P02');
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && ('' + val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
      if (val.constructor === Object && Object.keys(val).length === 0) return true
    },
    resetDate() {
      this.dates = ['', ''];
    },
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
  },

  mounted() {

    console.log("dsadsadsadsa");
    console.log("dsadsadsadsa");
    console.log("dsadsadsadsa");
    this.BRD_ID = this.getBrdId();

        console.log("BRD_ID  BRD_IDBRD_ID  " + this.BRD_ID);
    this.getSelBoxList();

  },

  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },

    dateRangeText() {
      let returnVal = '';
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];

      if (!this.isEmpty(newStartDate) && !this.isEmpty(newEndDate)) {
        returnVal = newStartDate + " ~ " + newEndDate;
      } else if (!this.isEmpty(newStartDate) && this.isEmpty(newEndDate)) {
        returnVal = newStartDate + " ~ "
      } else if (this.isEmpty(newStartDate) && !this.isEmpty(newEndDate)) {
        returnVal = " ~ " + newEndDate;
      }
      return returnVal;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`
    },

    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",


    }),

  },
};
</script>